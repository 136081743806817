<template>
    <div :style="data.Style" :class="data.Class">
        <div :class="data.ContainerClass">
            <div :class="data.Left.Class" v-if="data.Left">
                <span>{{ data.Left.Text }}</span>
            </div>
            <div :class="data.Right.Class" v-if="data.Right">
                <fields :fields="data.Right.Fields" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        Fields: () => import('./components/fields')
    },
    props: {
        data: {
            type: Object
        }
    }
}
</script>
